var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "container"
  }, [_c('div', {
    staticClass: "row bg-white p-2"
  }, [_vm._m(0), _c('div', {
    staticClass: "form-group container"
  }, [_vm._m(1), _c('input', {
    staticClass: "form-control",
    attrs: {
      "type": "text",
      "id": "usr"
    }
  }), _vm._m(2), _c('quill-editor', {
    staticClass: "mb-1",
    attrs: {
      "options": _vm.editorOptions
    },
    model: {
      value: _vm.item.content,
      callback: function callback($$v) {
        _vm.$set(_vm.item, "content", $$v);
      },
      expression: "item.content"
    }
  }), _vm._m(3), _c('input', {
    staticClass: "form-control",
    attrs: {
      "type": "text",
      "id": "usr"
    }
  }), _vm._m(4), _c('input', {
    staticClass: "form-control",
    attrs: {
      "type": "file",
      "id": "usr"
    }
  }), _vm._m(5), _c('br'), _c('hr'), _c('br'), _c('p', [_vm._v("Note:")]), _vm._m(6)], 1)])]);
}
var staticRenderFns = [function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "col-md-12 d-flex justify-content-between"
  }, [_c('p', {
    staticStyle: {
      "font-size": "1.3rem"
    }
  }, [_vm._v("Bank Video")])]);
} /* staticRenderFnsSpliter */, function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('p', {
    staticClass: "mt-1"
  }, [_c('strong', [_vm._v("Judul"), _c('span', {
    staticClass: "text-danger"
  }, [_vm._v(" (*)")])])]);
} /* staticRenderFnsSpliter */, function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('p', {
    staticClass: "mt-1"
  }, [_c('strong', [_vm._v("Deskripsi"), _c('span', {
    staticClass: "text-danger"
  }, [_vm._v(" (*)")])])]);
} /* staticRenderFnsSpliter */, function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('p', {
    staticClass: "mt-1"
  }, [_c('strong', [_vm._v("Durasi waktu"), _c('span', {
    staticClass: "text-danger"
  }, [_vm._v(" (*)")])])]);
} /* staticRenderFnsSpliter */, function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('p', {
    staticClass: "mt-1"
  }, [_c('strong', [_vm._v("File video"), _c('span', {
    staticClass: "text-danger"
  }, [_vm._v(" (*)")])])]);
} /* staticRenderFnsSpliter */, function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "mt-5"
  }, [_c('button', {
    staticClass: "btn btn-success mr-1",
    attrs: {
      "type": "button"
    }
  }, [_vm._v("Simpan")]), _c('button', {
    staticClass: "btn btn-outline-success mr-1",
    attrs: {
      "type": "button"
    }
  }, [_vm._v("Simpan dan Buat Lagi")]), _c('button', {
    staticClass: "btn btn-outline-primary",
    attrs: {
      "type": "button"
    }
  }, [_vm._v("Reset")])]);
} /* staticRenderFnsSpliter */, function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('p', [_vm._v("Tanda bintang"), _c('strong', [_c('span', {
    staticClass: "text-danger"
  }, [_vm._v(" (*)")])]), _vm._v(" berarti wajib diisi/pilih")]);
}]

export { render, staticRenderFns }